html, body, #__next {
  height: 100%;
  overscroll-behavior-y: none;
}

svg {
  fill: currentColor;
}

#nprogress {
  position: relative;
  z-index: 9999999;
}

#nprogress .bar {
  background: #112658;
  height: 5px;
}

#nprogress .peg {
  box-shadow: 0 0 10px #112658, 0 0 5px #112658;
}
